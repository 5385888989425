import NextI18Next from 'next-i18next';

const nextI18NextInstance = new NextI18Next({
  defaultLanguage: 'en-gb',
  otherLanguages: ['de', 'ca', 'es', 'zh-cn'],
  lowerCaseLng: true,
  localeSubpaths: {
    'en-gb': 'en-gb',
    de: 'de',
    ca: 'ca',
    es: 'es',
    'zh-cn': 'zh-cn',
  },
  react: {
    useSuspense: false, // setting useSuspense to false will solve your problem as React will not need fallback UI anymore.
    transEmptyNodeValue: '', // what to return for empty Trans
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'a'], // don't convert to <1></1> if simple react elements
  },
  defaultNS: 'public',
  fallbackNS: 'public',
  ns: ['public', 'student_portal'],
  fallbackLng: 'en-gb',
  keySeparator: false,
});

export default nextI18NextInstance;

export const {
  Trans,
  Link,
  Router,
  i18n,
  initPromise,
  config,
  useTranslation,
  withTranslation,
  appWithTranslation,
} = nextI18NextInstance;
